
const Paragraph2 = () => {
    return (
        <div className="right-pagraph">
            <h3>Consent </h3>
            <p>
                By using our website, you hereby consent to our Privacy Policy and agree to its terms.
            </p>
        </div>
    );
}
 
export default Paragraph2;

const Paragraph2 = () => {
    return (
        <div className="right-pagraph">
            <h3>Global Triumph Website Access Agreement </h3>
            <p>
                <span className="span">THIS AGREEMENT</span> is entered into between Global Triumph Market Plus Multiconcept (“GTMPM”) and any individual, corporation, association, agency, company, or other entity who accesses or uses this Website (the "User" or "You"). 
                Global Triumph Market Plus Multiconcept Website, which is provided without charge to you, is a World Wide Web site on the Internet that is designed to allow Global Triumph Market Plus Multiconcept and its Users communicate with each other. It contains or may contain information, communications, opinions, text, graphics, links, electronic art, animations, audio, video, software, photos, music, sounds and other material and data (collectively, "Content") formatted, organised and collected in a variety of forms that are generally accessible to Users, including directories and databases, and areas of the  Website that can be modified by Users, such as  uploading multimedia files, registering User profiles, and creating auto-notify. </p>
        </div>
    );
}
 
export default Paragraph2;
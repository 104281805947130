
const Paragraph8 = () => {
    return (
        <div className="right-pagraph">
            <h3>4. Termination </h3>
            <p>The only right with respect to dissatisfaction with any policies, guidelines, or practices of Global Triumph Market Plus Multiconcept in operating the Global Triumph Market Plus Multiconcept  Website, or any change in Content, is for you to discontinue accessing the Global Triumph Market Plus Multiconcept Website. Global Triumph Market Plus Multiconcept may terminate or temporarily suspend your access to all or any part of the Global Triumph Market Plus Multiconcept   Website, without notice, for conduct that Global Triumph Market Plus Multiconcept believes is a violation of this Agreement or any policies or guidelines posted by Global Triumph Market Plus Multiconcept, or for other conduct which Global Triumph Market Plus Multiconcept believes, in its sole discretion, is harmful to Global Triumph Market Plus Multiconcept or other Users. </p>

            <p>Global Triumph Market Plus Multiconcept may discontinue operating the Global Triumph Market Plus Multiconcept Website and terminate this Agreement without notice at any time for any reason in its sole discretion. In the event of termination, a User would no longer be authorised to access the Global Triumph Market Plus Multiconcept Website, including the Interactive Areas, and the restrictions imposed on you with respect to Content downloaded from the Global Triumph Market Plus Multiconcept Website, as well as the disclaimers and limitations of liabilities set forth in this agreement, shall survive. </p>
        </div>
    );
}
 
export default Paragraph8;
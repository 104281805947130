
const Paragraph10 = () => {
    return (
        <div className="right-pagraph">
            <h3>CCPA Privacy Rights (Do Not Sell My Personal Information) </h3>
            <p>
                Under the CCPA, among other rights, California consumers have the right to:
            </p>
            <p>
                Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
            </p>
            <p>
                Request that a business delete any personal data about the consumer that a business has collected.
            </p>
            <p>
                Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
            </p>
            <p>
                If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
            </p>
        </div>
    );
}
 
export default Paragraph10;
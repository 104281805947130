import { Container, Row } from "react-bootstrap";

const InvestmentsHeader = () => {
    return (
        <section className="investment-header investment-top-header">
            <Container>
                    <h3>Why Join Our Investments Programs?</h3>
                <Row className='bx'>
                    <p>
                        It's an indisputable fact that that every investor wants to put money where it is secured and has the assurance of yielding interest within a reasonable
                        period, and this is what Global Triumph Market Plus Multiconcept
                        (GTMPM) offers. We are a company that has not only the interests of our
                        company but also the interest of our partners as well, because the success
                        of our investors or Partners goes a long way to impart our own success as
                        too. This is what we stand for.
                    </p>

                    <p>
                        Looking at the locations and characteristics of the projects, it is fair say that
                        the features of each of the Estate, shows prospects that within a limited
                        time of investment, the investor is assured of worthy returns. The features
                        highlighted below further buttress this point:
                    </p>

                    <p>
                        Monastery Road behind Novare Lekki Shoprite, Lekki/Epe Expressway in
                        Sangotedo; New Proposed Airport, Pan-African University, Dangote Sea
                        Port, Alaro City, Eleganza Warehouse in Oke Ogun Town, Ibeju Lekki and
                        the other ones in Epe.
                    </p>

                    <p>
                        The Island is often considered to be the desire of those who love luxury and
                        have the taste for good life in Lagos, it is therefore not out of place when
                        people Christened Sangotedo the Heartbeat of and Epe the New Lagos.
                    </p>

                    <p>
                        With the rest attention on this areas and the industrial surge focused there,
                        there has a been a very quick realization that it is the most fertile ground
                        for investments especially in Real Estate, and that is what we are offering.
                        The above landmarks are some of the most important features that make a
                        business marketable. And in no time one an investor is sure to smile to the
                        bank.
                    </p>
                </Row>
            </Container>
        </section>
    );
}
 
export default InvestmentsHeader;
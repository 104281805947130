
const Paragraph16 = () => {
    return (
        <div className="right-pagraph">
            <h3>11. The Vendors Right of Revocation </h3>
            <p>The vendors right of relocation shall ensure at the expiration of a forty-five (45) days grace period given to the purchaser at the failure of the purchaser to make payment in line with the agreed milestone or as may be agreed in writing by parties. In such instance;</p>
            <p>The vendor will by written notice inform the buyer of its right to take delivery of the property.</p>
            <p>At delivery of the revoked property, the vendor will refund the buyer of his deposited sum less thirty percent (30%) administrative charge, subject to the property being reallocated.</p>
        </div>
    );
}
 
export default Paragraph16;

const Paragraph3 = () => {
    return (
        <div className="right-pagraph">
            <h3>Information we collect </h3>
            <p>
                The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
            </p>
            <p>
                If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
            </p>
            <p>
                When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
            </p>
        </div>
    );
}
 
export default Paragraph3;

const Paragraph14 = () => {
    return (
        <div className="right-pagraph">
            <h3>Contact Us </h3>
            <p>
                If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
            </p>
        </div>
    );
}
 
export default Paragraph14;

const Paragraph1 = () => {
    return (
        <div className="left-pagraph">
            <h3>Global Triumph Website Access Agreement </h3>
            <p>
            Welcome to the Global Triumph Market Plus Multiconcept (“GTMPM”) website (this “Website”). Please read the following terms and condition (“Agreement”) carefully before accessing the Website. These terms and conditions shall govern your access to and use of the website. Access to this Website is on the condition that you or the entity you represent agree to be bound by the terms of this Agreement. Use of or access to this Website shall constitute acceptance of the terms and conditions set out below and our Privacy Policy (available here). These terms and conditions, which may be modified from time to time and apply to all visitors or Users of this Website.
            If you do not wish to be bound by this Agreement, do not access this Website. If you have any questions about this Agreement, please contact us through our support e-mail (•). 

            </p>
        </div>
    );
}
 
export default Paragraph1;
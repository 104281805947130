
const Paragraph4 = () => {
    return (
        <div className="right-pagraph">
            <h3>How we use your information </h3>
            <p>
                We use the information we collect in various ways, including to:
            </p>
            <ul>
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyze how you use our website </li>
                <li>Develop new products, services, features, and functionality</li>
                <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes </li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
            </ul>
        </div>
    );
}
 
export default Paragraph4;

const Paragraph12 = () => {
    return (
        <div className="right-pagraph">
            <h3>7. Electronic Transmissions </h3>
            <p>By accepting the receipt of Electronic Transmissions, you voluntarily agree to have all records, including your current and future investment-related documents, provided to you in electronic form. Investment-related documents include, but are not limited to: </p>

            <ol type="i">
                <li>Your personal information or documents, notices and correspondence related to your investments. This could include ID cards, applications, amendments, endorsements, illustrations, questionnaires, disclosures, and reports;</li>
                <li>Bills, billing notices, payment schedules or any other correspondence related to your payments; </li>
                <li>Legally mandated policies, notices, and disclosures to inform you of our business practices, including notices related to our collection, storage, use, and disclosure of your personal information; and</li>
                <li>Any other documents related to your financial transactions with us.</li>
            </ol>

            <p>We will use various methods to provide communications to you electronically, including via e-mail or through our website or app for customers or Users who have installed the mobile App or who use the web App. For this second option, you may need to log in to access the information.</p>

            <p>This consent, unless withdrawn, applies to all transactions between you and Global Global Triumph Market Plus Multiconcept.</p>
        </div>
    );
}
 
export default Paragraph12;
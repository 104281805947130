
const Paragraph6 = () => {
    return (
        <div className="right-pagraph">
            <h3>Cookies and Web Beacons </h3>
            <p>
                Like any other website, Global Triumph Market Plus Multiconcept uses "cookies". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
            </p>
        </div>
    );
}
 
export default Paragraph6;

const Paragraph12 = () => {
    return (
        <div className="right-pagraph">
            <h3>Children's Information </h3>
            <p>
                Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
            </p>
            <p>
                Global Triumph Market Plus Multiconcept does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
            </p>
        </div>
    );
}
 
export default Paragraph12;

const Paragraph3 = () => {
    return (
        <div className="right-pagraph">
            <h3>1. User Rights and Responsibilities </h3>
            <p>1.1 Access to the Global Triumph Market Plus Multiconcept Website 
            You are responsible for providing all hardware, software, data, or other communications equipment and/or service to connect to the Internet and access the Global Triumph Market Plus Multiconcept Website. You are also responsible for all internet access charges, telephone charges or other fees or charges incurred in connecting to the internet and accessing the Website. </p>
            
            <p><b>1.2 User Conduct </b></p>
            <p>You agree to access and use the Global Triumph Market Plus Multiconcept Website only for lawful purposes. You are solely responsible for the knowledge of and adherence to any and all laws, statutes, rules and regulations, pertaining to: </p>
            <ol type="I">
                <li>your use of this Website, including any interactive area; </li>
                <li>the use of any networks or other services connected to this Website; and </li>
                <li>the communication means by which you connect your modem, computer, or other equipment to this Website. </li>
            </ol>
        </div>
    );
}
 
export default Paragraph3;

const Paragraph11 = () => {
    return (
        <div className="right-pagraph">
            <h3>GDPR Data Protection Rights </h3>
            <p>
                We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
            </p>
            <p>
                The right to access - You have the right to request copies of your personal data. We may charge you a small fee for this service.
            </p>
            <p>
                The right to rectification - You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
            </p>
            <p>
                The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
            </p>
            <p>
                The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
            </p>
            <p>
                The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
            </p>
            <p>
                The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
            </p>
            <p>
                If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
            </p>
        </div>
    );
}
 
export default Paragraph11;
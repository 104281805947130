import { Container, Row } from "react-bootstrap";

const InvestmentsFooter = () => {
    return (
        <section className="investment-header">
            <Container>
                <Row className='bx'>
                    <p>
                    We also have this very special alternative offer where investors who can provide the sum of a <span className="span">One Billion, Three Hunred Million Naira (N1,300,000,000)</span> will be allocated <span className="span">Fifty (50) plots of land</span> in this very choice and fast developing part of Lekki. <br />
                    A plot of land around this same location goes for between <span className="span">N25 Million Naira</span> and over <span className="span">N50 Million Naira</span> depending on a few factors of which the land in our proposal has satisfied all of the factors.
                    </p>

                    <p>
                        It therefore means that our investors will be getting a plot for as low as <span className="span">N18 Million Naira</span> with instant returns of about <span className="span">300%</span> to <span className="span">over 1,200%</span>.
                    </p>

                    <p>
                        We look forward to giving detailed explanations and clarification when needed.
                    </p>

                    <p>
                        All documents are verified/verifiable/available for verification upon inspection/meeting. <br />
                        We look forward to your invitation for further submissions, detailed explanations, negotiations, etc., when needed.
                    </p>

                    <p>
                        Thanks for your anticipated interest to invest in our projects.
                    </p>
                </Row>
            </Container>
        </section>
    );
}
 
export default InvestmentsFooter;

const Paragraph5 = () => {
    return (
        <div className="right-pagraph">
            <h3>2. Intellectual Property Rights </h3>

            <p><b>2.1 Global Triumph Market Plus Multiconcept Website Content  </b></p>
            <p>You agree to access and use the Global Triumph Market Plus Multiconcept Website only for lawful purposes. You are solely responsible for the knowledge of and adherence to any and all laws, statutes, rules and regulations, pertaining to: </p>
            <ol type="a">
                <li>You acknowledge that the Content on this Website is generally provided by Global Triumph Market Plus Multiconcept. You acknowledge that the Website permits access to content that is protected by copyrights, trademarks, and other proprietary (including intellectual property) rights ("Intellectual Property Rights"), and that these Intellectual Property Rights are valid and protected in all media existing now or later developed and except as is explicitly provided below, your use of content shall be governed by applicable copyright and other intellectual property laws. You acknowledge that the Global Triumph Market Plus Multiconcept Website owns a copyright in the "look and feel," i.e., the selection, coordination, arrangement, and presentation of such content. </li>
                <li>You may not modify, copy, reproduce, transmit, distribute, publish, create derivative works from, display or otherwise transfer or commercially exploit any of the Content, in whole or in part, provided, however, that you may; </li>
                <ol type="i">
                    <li>make a reasonable number of digital or other forms of copies to permit your computer hardware and software to access and view the Content; </li>
                    <li>print one copy of each piece of Content; and</li>
                    <li>make and distribute a reasonable number of copies of Content, in whole or in part, in hard copy or electronic form for internal use only.  Any permitted copies of Content must reproduce in an unmodified form any notices contained in the Content, such as all Intellectual Property Right notices, and an original source attribution to Global Triumph Market Plus Multiconcept and its URL address. You acknowledge that this Website, its contributors, and/or Users </li>
                    <li>remain the owners of the Content and that you do not acquire any Intellectual Property Rights by downloading or printing Content. </li>
                </ol>
                
            </ol>
        </div>
    );
}
 
export default Paragraph5;

const Paragraph8 = () => {
    return (
        <div className="right-pagraph">
            <h3>Advertising Partners Privacy Policies </h3>
            <p>
                You may consult this list to find the Privacy Policy for each of the advertising partners of Global Triumph Market Plus Multiconcept.
            </p>
            <p>
                Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Global Triumph Market Plus Multiconcept, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
            </p>
            <p>
                Note that Global Triumph Market Plus Multiconcept has no access to or control over these cookies that are used by third-party advertisers.
            </p>
        </div>
    );
}
 
export default Paragraph8;
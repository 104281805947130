
const Paragraph9 = () => {
    return (
        <div className="right-pagraph">
            <h3>Third Party Privacy Policies </h3>
            <p>
                Global Triumph Market Plus Multiconcept's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
            </p>
            <p>
                You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
            </p>
        </div>
    );
}
 
export default Paragraph9;

const Paragraph1 = () => {
    return (
        <div className="left-pagraph">
            <h3>Global Triumph Website Privacy Agreement </h3>
            <p>
                At Global Triumph Market Plus Multiconcept, accessible from globaltriumph.ng, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Global Triumph Market Plus Multiconcept and how we use it. 
            </p>
            <p>
                If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
            </p>
            <p>
                This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Global Triumph Market Plus Multiconcept. This policy is not applicable to any information collected offline or via channels other than this website.
            </p>
        </div>
    );
}
 
export default Paragraph1;
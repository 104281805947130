
const Paragraph17 = () => {
    return (
        <div className="right-pagraph">
            <h3>12. Government Law and Dispute Resolution </h3>
            <ol type="i">
                <li>This agreement and rights of the parties hereunder shall be governed by and construed in accordance with Nigerian Law.</li>
                <li>The parties shall endeavor to settle amicably any dispute difference or claim arising whether directly or indirectly from this agreement.</li>
                <li>If the dispute, difference, or claim is not settled amicably within thirty (30) days, the dispute or difference or claim may be referred to the Lagos Multi-Door Courthouse (LMDC) for mediation, which shall be concluded in accordance with the LMDC Mediation rules or such other as mutually agreed by the parties.</li>
                <li>If for any reason, the dispute is not resolved by the mediation, either of the parties may refer the dispute to arbitration for adjudication by a sole arbitration for adjudication who has knowledge in construction appointed by the president, Chartered Institute of Arbitrators, UK (Nigerian Branch) to preside over the dispute. The arbitration will be conducted in English in accordance with the Provisions of the Arbitration and Conciliation Act Cap A18, Laws of the Federation of Nigeria 2004. </li>
                <li>Each party shall bear his own costs and would be liable to contribute an equal amount in respect of fees to be paid to the Mediator and the Sole Arbitrator.</li>
                <li>Any Award of the Arbitrator shall be in writing and shall be final, conclusive and binding upon the Parties.</li>
            </ol>
        </div>
    );
}
 
export default Paragraph17;

const Paragraph11 = () => {
    return (
        <div className="right-pagraph">
            <h3>6. Indemnity </h3>
            <p>You agree to indemnify and hold Global Triumph Market Plus Multiconcept, its subsidiaries, affiliates and their directors, employees, and agents from any and all liabilities, claims and expenses, including reasonable attorney’s fees, arising from breach of this Agreement, any other policy, your use or access of the Global Triumph Market Plus Multiconcept Website or any Internet site linked to or from the Global Triumph Market Plus Multiconcept Website, or in connection with the transmission of any Content on the Global Triumph Market Plus Multiconcept Website.  </p>
        </div>
    );
}
 
export default Paragraph11;
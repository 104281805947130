
const Paragraph7 = () => {
    return (
        <div className="right-pagraph">
            <h3>3. Interactive Areas </h3>
            <p>You acknowledge that this Website may include various interactive areas ("Interactive Areas"). These Interactive Areas allow feedback to Global Triumph Market Plus Multiconcept and real-time interaction between Users. Your use of an Interactive Area will be governed by this Agreement, as applicable. </p>
            <p>You recognize that Global Triumph Market Plus Multiconcept cannot, and does not intend to, screen communications in advance. Moreover, because the Global Triumph Market Plus Multiconcept Website encourages open and candid communication in the Interactive Areas, Global Triumph Market Plus Multiconcept cannot determine in advance the accuracy or conformance to this Agreement of any Content transmitted in an Interactive Area. Global Triumph Market Plus Multiconcept is not responsible for screening, policing, editing, reviewing, or monitoring any Content in an Interactive Area. </p>

            <p>Notwithstanding the above, you agree that Global Triumph Market Plus Multiconcept has the right to monitor any Interactive Area, from time to time and to disclose any information as necessary to satisfy any law, regulation, or other governmental requests, to operate the Interactive Area, or to protect itself or other Users. If notified of Content that is alleged not to conform to this Agreement, Global Triumph Market Plus Multiconcept may investigate the allegation and determine in its sole discretion whether to remove or request the User to remove such Content. Global Triumph Market Plus Multiconcept reserves the right to prohibit conduct, communication, or Content within an Interactive Area, or to edit, refuse to post, or to remove any Content, in whole or in part, which it deems in its sole discretion to: </p>

            <ol type="i">
                <li>violate the provisions of this Agreement or any other standard, written Global Triumph Market Plus Multiconcept Website policy in effect at that time;</li>
                <li>be harmful to the rights of any User, Global Triumph Market Plus Multiconcept or other third parties;</li>
                <li>violate applicable law; or</li>
                <li>be otherwise objectionable. </li>
            </ol>
        </div>
    );
}
 
export default Paragraph7;
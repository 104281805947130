
const Paragraph15 = () => {
    return (
        <div className="right-pagraph">
            <h3>10. The Purchaser Covenants as follows: </h3>
            <ol type="i">
                <li>The purchaser shall bear the cost of the preparation of the survey Plan and Deed in its name, of which the purchaser has fully paid for the survey plan, remaining the deed of assignment.</li>
                <li>The purchaser shall contribute proportionate share/cost of infrastructure and development levies to be communicated to subscribers at the appropriate time</li>
                <li>The purchaser shall endorse and comply with the rules and regulations of the estate to be provided by the vendor prior to during or after allocation of the land within the estate.</li>
                <li>To pay the legal fee in respect of the land to be allocated to the purchaser which the purchaser has fully paid for.</li>
                <li>If the purchaser or investor wishes to withdraw any time, he shall; Give ninety (90) working days withdrawal notice to us and also pay administrative charges of 30% of the purchase price.</li>
                <li>To pay the survey fee before allocation and other development, documents and maintenance fees subsequently.</li>
            </ol>
        </div>
    );
}
 
export default Paragraph15;

const Paragraph7 = () => {
    return (
        <div className="right-pagraph">
            <h3>Our Advertising Partners </h3>
            <p>
                Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.
            </p>
            <ul>
                <li><a href="https://policies.google.com/technologies/ads">Google</a></li>
            </ul>
        </div>
    );
}
 
export default Paragraph7;

const Paragraph14 = () => {
    return (
        <div className="right-pagraph">
            <h3>9. Miscellaneous </h3>
            <p>Global Triumph Market Plus Multiconcept may revise this Agreement or any other policy at any time and if any such revision is unacceptable to you, you must discontinue accessing the Global Triumph Market Plus Multiconcept Website. Your continued accessing and use of this Website following notice of any such revision shall conclusively be deemed acceptance of all such revisions. The provisions of Sections 1.2, 2.1, 2.2, 5.1, 5.2, 5.3, 6, 7 and 8 shall survive the termination or expiration of this Agreement. </p>

            <p>If any provision of this Agreement or any other policy be held invalid or unenforceable, that portion shall be construed in accordance with applicable law as nearly as possible to reflect the original intention of the parties and the remaining portions will continue in full force and effect. The failure of Global Triumph Market Plus Multiconcept to insist upon or enforce strict performance of any provision of this Agreement shall not be construed as a waiver of any provision or right. This Agreement shall be governed by the laws of England and Wales and You and Global Triumph Market Plus Multiconcept each submit to the exclusive jurisdiction of the courts of England and Wales. This Agreement is personal to You, and you may not assign your rights or obligations thereunder to anyone else.</p>
        </div>
    );
}
 
export default Paragraph14;

const Paragraph13 = () => {
    return (
        <div className="right-pagraph">
            <h3>8. Fraud </h3>
            <p>As a User, you shall not use the products or services in connection with any illegal or fraudulent business activities under any laws or regulations of any applicable jurisdiction.</p>
        </div>
    );
}
 
export default Paragraph13;

const Paragraph6 = () => {
    return (
        <div className="right-pagraph">
            <p><b>2.2 Content Provided by User </b></p>
            <p>
            You may upload to any Interactive Area or otherwise transmit, post, publish, reproduce, or distribute, on or through the Global Triumph Market Plus Multiconcept Website only Content that is not subject to any Intellectual Property Rights, or Content in which any holder of Intellectual Property Rights has given express authorisation for distribution over the internet and on the Global Triumph Market Plus Multiconcept Website, without restriction whatsoever. </p>
            <p>Any Content submitted with the consent of a copyright owner other than you should contain a phrase such as "Copyright owned by [name of owner]; Used by Permission." By submitting Content to any Interactive Area, you automatically grant and/or warrant that the owner of such Content, whether it be You or a third party, has expressly granted to Global Triumph Market Plus Multiconcept the royalty-free, perpetual, irrevocable, nonexclusive, unrestricted, worldwide right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, sublicense, distribute, perform, and display such Content, in whole or in part, worldwide and/or to incorporate it in other works in any form, media, or technology now known or later developed for the full term of any Intellectual Property Rights that may exist in such Content. You also permit Global Triumph Market Plus Multiconcept to sublicense to third parties the unrestricted right to exercise any of the foregoing rights granted with respect to such Content. 
 </p>
        </div>
    );
}
 
export default Paragraph6;

const Paragraph4 = () => {
    return (
        <div className="right-pagraph">
            <p>By accessing this Website, you agree that you will not:  </p>
            <ul>
                <li>Restrict or inhibit any other user from using the Website;</li>
                <li>Post or transmit any unlawful, threatening, abusive, defamatory, obscene, vulgar, pornographic, profane, or indecent information of any kind, including without limitation any transmissions constituting or encouraging conduct that would constitute a criminal offence, give rise to civil liability or otherwise violate any local, state, national, or international law; </li>
                <li>Post or transmit any information, software, or other materials which violates or infringes on the rights of others, including material which is an invasion of privacy or publicity rights or which is  </li>
                <li>protected by copyright, trademark or other proprietary right, or derivative works with respect thereto, without first obtaining permission from the owner or right holder;</li>
                <li>Post or transmit any information, software or other material which contains a virus or other harmful contents; </li>
                <li>Alter, damage or delete any content or other communications that are not your own content or to otherwise interfere with the ability of others to access the Global Triumph Website;</li>
                <li>Disrupt the normal flow of communication in an interactive area;
                </li>
                <li>Claim a relationship with or to speak for any business, association, institution or other organization for which you are not authorised to claim such a relationship; and</li>
                <li>Violate any operating rule, policy or guideline of your Internet access provider or online service. </li>
            </ul>
        </div>
    );
}
 
export default Paragraph4;
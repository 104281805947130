import { Container } from "react-bootstrap";

const ProductUpdateBody = () => {
    return (
        <section className="product-updates">
            <Container>
                <div className="body">
                    <p>
                        The development of the Global Triumph Market Plus Multiconcept applications  began in April, 2023. By the following month, the developers' team has completed the main company website while fasttracking the development of the user and admin web applications. The full web applications which include the company website, the user web app and the admin web dashboard are all delivered by th end oif May 2023.
                    </p>

                    <p>
                        The focus continue afterwards on the delivery of the mobile applications for both Android and iOS devices. At present, the application is nearly finished and undergoing private testing, with 95% completion. Upon completion, we will release versions of the application for web, Android, and iOS.
                    </p>
                </div>
            </Container>
        </section>
    );
}
 
export default ProductUpdateBody;
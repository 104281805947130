
const Paragraph10 = () => {
    return (
        <div className="right-pagraph">
            <p><b>5.2 Limitation of Liability </b></p>
            <p>Under no circumstances shall Global Triumph Market Plus Multiconcept or its subsidiaries, affiliates, or their respective directors, officers, employees, agents, contractors, or licensors, be liable for any direct or incidental, special or consequential damages under or arising from this Agreement, this Website, or any internet site linked to or from this Website, whether for breach of contract, tortuous behaviour, negligence, or under any other cause of action, including without limitation, any liability for damages caused or allegedly caused by any failure of performance, error, omission, interruption, electrical surge/damage/interference, deletion, defect, delay in operation or transmission, computer virus, communications line failure, breakdown of equipment, software error, infringement, unauthorized access to, or theft, destruction, alteration, or use of, records.  </p>

            <p>Under no circumstances shall Global Triumph Market Plus Multiconcept or its subsidiaries, affiliates, or their respective directors, employees, agents, contractors, or licensors, be liable to You or any other third party for any decision made or action taken by You in reliance on the content contained within this Website or the content contained within any Internet site linked to or from this Website. The content within the Global Triumph Market Plus Multiconcept Website and the content within internet sites linked to or from this Website may include technical or other inaccuracies or typographical errors. Changes are periodically added to the content herein; these changes will be incorporated in new versions of this Website and specifically are included in this section of the agreement. Global Triumph Market Plus Multiconcept may make improvements and/or changes to the Content of the Website at any time at its discretion.  </p>

            <p>You specifically acknowledge and agree that Global Triumph Market Plus Multiconcept is not liable for any defamatory, offensive, fraudulent, or otherwise illegal conduct of any User. If You are dissatisfied with any Global Triumph Market Plus Multiconcept Website site content, or with the access agreement of the Global Triumph Market Plus Multiconcept Website, in whole or in part, your sole and exclusive remedy is to discontinue using the Global Triumph Market Plus Multiconcept Website. </p>
        </div>
    );
}
 
export default Paragraph10;

const Paragraph9 = () => {
    return (
        <div className="right-pagraph">
            <h3>5. Disclaimers of Warranties; Limitations of Liability  </h3>
            <p><b>5.1 Disclaimer of Warranties </b></p>
            <p>Please use your best judgment in evaluating all information contained or opinions expressed on this Website. It is the policy of Global Triumph Market Plus Multiconcept not to endorse or oppose any opinion expressed by a User or Content provided by a User, Contributor, or other independent party. You expressly agree that your use of this Website is at your sole risk. Neither Global Triumph Market Plus Multiconcept nor its subsidiaries, affiliates, or their respective directors, employees, agents, contractors, licensors or other suppliers providing content, data, information or services warrants that the Global Triumph Market Plus Multiconcept Website or any internet site linked to or from the Website will be uninterrupted or error-free, that defects will be corrected, or that this site, including the interactive areas, or the server that makes it available are free of viruses or other harmful components.  </p>

            <p>None of them also make any warranty as to the results that may be obtained from the use of this website or any internet site linked to or from the Website or as to the timeliness, sequence, accuracy, authority, completeness, usefulness, non-infringement, reliability, availability, or substance of any content, information, service, or transaction provided through this Website or any site linked to or from this Website. The Website is provided on an "as is," "as available" basis, without warranties of any kind, either express or implied, including, without limitation, those of merchantability and fitness for a particular purpose. </p>
        </div>
    );
}
 
export default Paragraph9;
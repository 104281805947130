
const Paragraph13 = () => {
    return (
        <div className="right-pagraph">
            <h3>Changes to This Privacy Policy </h3>
            <p>
                We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.
            </p>
        </div>
    );
}
 
export default Paragraph13;